import React from "react"
import * as styles from "./WhatIZBring.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import "./WhatIZBring.scss"
import Slider from "react-slick"
import { Col } from "react-bootstrap"

function Arrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainer}>
      <div className={styles.portfolioArrowRightIconCover} onClick={onClick}>
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/right_f5df90e3bd.svg"
          }
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}
function PrevArrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainerLeft}>
      <div className={styles.portfolioArrowIconCover} onClick={onClick}>
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/left_ccefba5791.svg"
          }
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}

const WhatIZBring = ({ strapiData }) => {
  const settings = {
    infinite: true,
    autoplay: false,
    slidesToShow: 3,
    // centerMode: true,
    centerPadding: "0px",
    slidesToScroll: 3,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 3,
          arrows: false,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          arrows: false,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          dotsClass: "metaverse",
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          dotsClass: "metaverse",
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <Row className={styles.headingAndDesc}>
            <Col xs={12} sm={12} md={12} lg={8} xl={8} className="p-0">
              <h2
                className={styles.heading}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.title,
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
              <p
                className={styles.desc}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
            </Col>
          </Row>
          <Row className="align-items-center clientSlider">
            <Slider {...settings} className={styles.SliderWidth}>
              {strapiData?.cards?.map(el => (
                <div className={styles.card}>
             
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      className={styles.imgContainer}
                    >
                      <img
                        src={el?.image1?.[0]?.localFile?.publicURL}
                        className={styles.quesColorSelected}
                        alt="cardImg"
                      />
                    </Col>
              
                  <p className={styles.cardHeading}>{el?.title}</p>
                  <p className={styles.description}>{el?.subTitle}</p>
                </div>
              ))}
            </Slider>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default WhatIZBring
