// extracted by mini-css-extract-plugin
export var description = "ImmersiveExperience-module--description--42867";
export var heading = "ImmersiveExperience-module--heading--3c111";
export var pointCard = "ImmersiveExperience-module--pointCard--d9bdc";
export var pointTitle = "ImmersiveExperience-module--pointTitle--86a9f";
export var rightCard = "ImmersiveExperience-module--rightCard--e5252";
export var rightCardDescription = "ImmersiveExperience-module--rightCardDescription--a2202";
export var rightCardHeading = "ImmersiveExperience-module--rightCardHeading--b9ecc";
export var sectionContainer = "ImmersiveExperience-module--sectionContainer--b536e";
export var selected = "ImmersiveExperience-module--selected--891d2";
export var smallCard = "ImmersiveExperience-module--smallCard--34885";
export var title = "ImmersiveExperience-module--title--7d038";