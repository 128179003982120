// extracted by mini-css-extract-plugin
export var aiPoweredCard = "MetaverseUseCases-module--aiPoweredCard--e3bf4";
export var cardContent = "MetaverseUseCases-module--cardContent--8617d";
export var cardDescription = "MetaverseUseCases-module--cardDescription--012c0";
export var cardHeading = "MetaverseUseCases-module--cardHeading--f3058";
export var counting = "MetaverseUseCases-module--counting--c0de9";
export var desc = "MetaverseUseCases-module--desc--01d41";
export var headingContainer = "MetaverseUseCases-module--headingContainer--db5bc";
export var keyPoint = "MetaverseUseCases-module--keyPoint--8209b";
export var keyPoints = "MetaverseUseCases-module--keyPoints--7dc6b";
export var mainContainer = "MetaverseUseCases-module--mainContainer--72840";
export var sectionBottomIllusion = "MetaverseUseCases-module--sectionBottomIllusion--8fb73";
export var sectionContainer = "MetaverseUseCases-module--sectionContainer--288a6";
export var sectionTopIllusion = "MetaverseUseCases-module--sectionTopIllusion--91519";
export var subTitle = "MetaverseUseCases-module--subTitle--a1d6d";
export var title = "MetaverseUseCases-module--title--7f795";
export var useCaseHeight = "MetaverseUseCases-module--useCaseHeight--1e04b";