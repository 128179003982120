// extracted by mini-css-extract-plugin
export var SliderWidth = "WordsFromClients-module--SliderWidth--ac20a";
export var blueText = "WordsFromClients-module--blueText--741f5";
export var clientContent = "WordsFromClients-module--clientContent--6f2a9";
export var coma = "WordsFromClients-module--coma--1e9fa";
export var content = "WordsFromClients-module--content--0d1c7";
export var customModal = "WordsFromClients-module--customModal--d4fb4";
export var dec = "WordsFromClients-module--dec--d33a5";
export var description = "WordsFromClients-module--description--6f2c7";
export var designation = "WordsFromClients-module--designation--d6384";
export var dot = "WordsFromClients-module--dot--821bb";
export var heading = "WordsFromClients-module--heading--d981e";
export var headingContainer = "WordsFromClients-module--headingContainer--10821";
export var iconContainer = "WordsFromClients-module--iconContainer--30e08";
export var iconContainerLeft = "WordsFromClients-module--iconContainerLeft--a6538";
export var logos = "WordsFromClients-module--logos--1da39";
export var logosBg = "WordsFromClients-module--logosBg--953eb";
export var mainContainer = "WordsFromClients-module--mainContainer--bd748";
export var name = "WordsFromClients-module--name--8552d";
export var playBtn = "WordsFromClients-module--playBtn--a9ee7";
export var portfolioArrowIcon = "WordsFromClients-module--portfolioArrowIcon--2cecb";
export var portfolioArrowIconCover = "WordsFromClients-module--portfolioArrowIconCover--e3c4c";
export var portfolioArrowRightIconCover = "WordsFromClients-module--portfolioArrowRightIconCover--cced4";
export var sectionBottomIllusion = "WordsFromClients-module--sectionBottomIllusion--1eefe";
export var sectionContainer = "WordsFromClients-module--sectionContainer--b1bfc";
export var sectionTopIllusion = "WordsFromClients-module--sectionTopIllusion--ec99a";
export var view = "WordsFromClients-module--view--fabc0";