// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--1f28b";
export var backgroundImg = "Banner-module--backgroundImg--92529";
export var bannerCurve = "Banner-module--bannerCurve--d3164";
export var bannerHeading = "Banner-module--bannerHeading--802e8";
export var btn = "Banner-module--btn--05ae5";
export var btne = "Banner-module--btne--9bec0";
export var curve = "Banner-module--curve--b3127";
export var dedicated = "Banner-module--dedicated--6e679";
export var dedicatedBanner = "Banner-module--dedicatedBanner--49fd0";
export var dedicatedHeadingStatic = "Banner-module--dedicatedHeadingStatic--5ed83";
export var description = "Banner-module--description--46d18";
export var innerDedicated = "Banner-module--innerDedicated--b0791";
export var newBtn = "Banner-module--newBtn--b4a07";
export var sliderButtons = "Banner-module--sliderButtons--ffd7c";
export var subTitle = "Banner-module--subTitle--a3bc0";