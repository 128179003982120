import React from "react"
import { Link } from "gatsby"
import * as styles from "./RealWorldImpact.module.scss"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

const data = [
  {
    images:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007654_b8e7b9380b.webp",
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/column_logo_396544fd95.png",
    title: "Revolutionizing Data with Smart Forms",
    head: "Smart forms reducing effort and boosting accuracy",
    button: "/portfolio/column/",
    button2: "Development",
  },
  {
    images:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007653_d770abba36.webp",
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/global_reader_logo_62f45929d7.png",
    title: "Delivering Fast, Seamless Onboarding",
    head: "New modules every 3 weeks for smooth onboarding and testing",
    button: "/portfolio/globalreader/",
    button2: "AI-Powered Precision",
  },
  {
    images:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/freshprep_6300c3130d.webp",
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/freshprep_Logo_956ab76922.png",
    title: "Revolutionizing the Insurance Industry with AI-Powered Solutions",
    head: "Introducing an AI Powered Solution Disrupting the Insurance Industry",
    button: "/portfolio/freshprep/",
    button2: "Resource Augmentation",
  },
  {
    images:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/nymcard_f49e61a3a3.webp",
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/nymcard_logo_e0308594bb.png",
    title: "Pioneering Innovation with Blockchain",
    head: "Partnered to innovate with a blockchain platform",
    button: "/portfolio/nymcard/",
    button2: "Application Modernisation",
  },
]

const RealWorldImpact = ({ strapiData }) => {
  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={strapiData?.subTitle ? 6 : 12} lg={strapiData?.subTitle ? 5 : 12}>
              <div className={styles.headSearch}>
                <p className={styles.heading} 
                 dangerouslySetInnerHTML={{
                  __html: strapiData?.title,
              }}
                />
              </div>
            </Col>
           {strapiData?.subTitle && <Col xs={12} sm={12} md={6} lg={6}>
              <p className={styles.description}>{strapiData?.subTitle}</p>
            </Col>}
          </Row>
          <Row className="align-items-center gap-30">
            <Col lg={12} md={12} sm={12} xs={12}></Col>

            {data?.map((e, i) => (
              <Col
                lg={6}
                md={12}
                sm={12}
                xs={12}
                key={i}
                className={styles.rowGap}
              >
                <div className={styles.caseImages}>
                  <img
                    src={e?.images}
                    alt="client logos"
                    decoding="async"
                    loading="lazy"
                    className={styles.clientImgs}
                  />
                  <div className={styles.content}>
                    <p>{e?.title}</p>
                    <h3>{e?.head}</h3>
                    <div className={styles.logos}>
                      <hr className={styles.line} />
                      <div className={styles.logoBtn}>
                        <img
                          src={e?.logos}
                          alt="client logos"
                          decoding="async"
                          loading="lazy"
                          className={styles.logoImages}
                        />
                      </div>
                    </div>
                  </div>

                  <Link to={e?.button} className={styles.btn2}>
                    <img
                      src="https://invozone-backend.s3.us-east-1.amazonaws.com/Vector_Stroke_123158fa91.png"
                      decoding="async"
                      loading="lazy"
                      className={styles.arrowImage}
                    />
                  </Link>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default RealWorldImpact
