// extracted by mini-css-extract-plugin
export var SliderWidth = "WhatIZBring-module--SliderWidth--9996e";
export var Trust = "WhatIZBring-module--Trust--df1c5";
export var card = "WhatIZBring-module--card--90431";
export var cardHeading = "WhatIZBring-module--cardHeading--7ee9a";
export var desc = "WhatIZBring-module--desc--b0cb3";
export var description = "WhatIZBring-module--description--80563";
export var heading = "WhatIZBring-module--heading--af08c";
export var headingAndDesc = "WhatIZBring-module--headingAndDesc--8a83a";
export var iconContainer = "WhatIZBring-module--iconContainer--d7698";
export var iconContainerLeft = "WhatIZBring-module--iconContainerLeft--4fdb1";
export var imgContainer = "WhatIZBring-module--imgContainer--00c34";
export var portfolioArrowIcon = "WhatIZBring-module--portfolioArrowIcon--5ba64";
export var portfolioArrowIconCover = "WhatIZBring-module--portfolioArrowIconCover--106e4";
export var portfolioArrowRightIconCover = "WhatIZBring-module--portfolioArrowRightIconCover--94bd9";
export var sheildImgTop = "WhatIZBring-module--sheildImgTop--73a11";
export var shieldTopBannerImg = "WhatIZBring-module--shieldTopBannerImg--9fcb1";