import { graphql, Script } from "gatsby"
import React from "react"
import ContactSales from "../../components/common/ContactSales"
import MainLayout from "../../layouts/MainLayout3"
import Banner from "../../components/metaverse-services/Banner"
import TrustInvoZone from "../../components/application-engineering/OverCountriesProduct"
import WordsFromClients from "../../components/metaverse-services/WordsFromClients"
import WhatIZBring from "../../components/metaverse-services/WhatIZBring"
import ImmersiveExperience from "../../components/metaverse-services/ImmersiveExperience"
import MetaverseUseCases from "../../components/metaverse-services/MetaverseUseCases"

import SEORevamp from "../../components/common/SEO_Revamp"
import WaysWeEngage from "../../components/metaverse-services/WaysWeEngage"
import RealWorldImpact from "../../components/metaverse-services/RealWorldImpact"

export const Head = ({ data }) => {
  // const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />

      <SEORevamp
        title={'metaTitle'}
        description={'metaDescription'}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/hire_elixir_developers_f701d7b38c.webp"
      />
    </>
  )
}

const MetaverseServices = ({ data }) => {

  const heroSection = data?.strapiPage?.sections[0]
  const immersiveExperience = data?.strapiPage?.sections[1]
  const metaverseUseCases = data?.strapiPage?.sections[2]
  const izBring = data?.strapiPage?.sections[3]
  const waysWeEngage = data?.strapiPage?.sections[4]
  const realWorldImpact = data?.strapiPage?.sections[5]
  const wordsFromClient = data?.strapiPage?.sections[6]

 

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })


  return (
    <MainLayout bgChanged={true} schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      <TrustInvoZone hideCurve={true} />
      <ImmersiveExperience strapiData={immersiveExperience} />
      <MetaverseUseCases strapiData={metaverseUseCases} />
      <WhatIZBring strapiData={izBring} />
      <WaysWeEngage strapiData={waysWeEngage} />
      <RealWorldImpact strapiData={realWorldImpact} />
      <WordsFromClients strapiData={wordsFromClient} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query mobileAppDevelopments {
    strapiPage(slug: { eq: "metaverse-services" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          jsonData {
            metaverse{
              metaverseServices
            }
          }
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
          
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default MetaverseServices;
