import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./ImmersiveExperience.module.scss"
import arrowRight from "../../images/ai-development-page/arrowRight.svg"

const ImmersiveExperience = ({ strapiData }) => {
  const [selected, setSelected] = useState(strapiData?.cards?.[0])

  return (
    <div className={styles.sectionContainer}>
      <Container>
        <div className="text-center">
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{
              __html: strapiData?.title,
            }}
          />
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
        </div>
      </Container>
      <Container>
        <Row>
          <Col xs={12} sm={12} lg={6} md={12}>
            <div>
              <Row>
                {strapiData?.cards?.map((el, ind) => (
                  <Col xs={12} sm={12} md={6} key={`${el?.title}_${ind}`}>
                    <div
                      className={`${styles.smallCard} ${
                        selected?.title === el?.title && styles.selected
                      }`}
                      onClick={() => setSelected(el)}
                    >
                      <img
                        src={
                          selected?.title === el?.title
                            ? el?.image1?.[0]?.localFile?.publicURL
                            : el?.image2?.[0]?.localFile?.publicURL
                        }
                        alt="img"
                      />
                      <p className={styles.title}>{el?.title}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={12} className={styles.rightCard}>
            <img
              src={selected?.image1?.[0]?.localFile?.publicURL}
              alt="img"
              height={88}
              width={88}
            />
            <p className={styles.rightCardHeading}>{selected?.title}</p>
            <p className={styles.rightCardDescription}>{selected?.subTitle}</p>
            <Row>
              {selected?.jsonData?.metaverse?.metaverseServices?.map(
                (el, ind) => (
                  <Col xs={12} sm={12} lg={6} md={12} key={`${ind}_${el}`}>
                    <div className={styles.pointCard}>
                      <img src={arrowRight} alt="img" height={22} width={22} />
                      <p className={styles.pointTitle}>{el}</p>
                    </div>
                  </Col>
                )
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ImmersiveExperience
